.patron-details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    padding: 20px 0;
    margin: 20px auto;

    @media(max-width: 767px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    img{
        
        height: 300px;
        width: 300px;
        border-radius: 50%;
        object-fit: cover;
        @media(max-width: 767px){
            width: 250px;
            height: 250PX;
            
        }
    }
    .section{
        align-self: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;

        
        @media(max-width: 767px)
        {
           align-items: center;
           text-align: center;
        }
        
    }
}