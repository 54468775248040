.pricing-container {
  margin: 0;
  padding: 0;

  .headline {
    margin: 20px auto;
    width: 90%;
    h1 {
      margin: 30px 0 5px;
      font-size: 24px;
      text-align: center;
    }
    p {
      text-align: center;
      margin: 10px 0px;
    }
  }
  .plans {
    display: grid;
    grid-template-columns: 1fr 1fr;

    margin: 30px 0px;
    max-height: auto;
    max-width: 75%;
    margin: auto;
    padding: 10px;
    gap: 50px;
    text-align: center;

    @media (max-width: 767px) {
      max-width: 90%;
      grid-template-columns: 1fr;
    }
    .planA {
      box-shadow: 10px 10px 20px 15px hsla(0, 0%, 75.3%, 0.288) !important;
      padding: 13px;
      h3 {
        margin-top: 20px;
        text-align: center;
        font-size: 25px;
        font-weight: 100px;
      }
      .firstpara {
        text-align: center;
      }
      p {
        padding: 20px;
        // color: red;
        text-align: justify;
        word-spacing: -2px;
        h4 {
          span {
            font-size: 60px;
            color: #1a73e8;
          }
        }
      }
      ul {
        text-align: left;
        li {
          padding: 10px;
        }
      }
    }
  }

  .plan-end {
    width: 90%;
    margin: 20px auto;
    text-align: center;
    p {
      margin-top: 10px;
    }
  }
}
