.search-bar{
    position: relative;

    @media(max-width: 767px){
        position: unset;
    }
    

    .search-result{
        position: absolute;
        background-color: #ffff;
        box-shadow: 0 4px 11px 3px hsla(0,0%,75.3%,.288)!important;
        border-radius: 0 0 10px 10px;
        border-top: 1px solid #00000020;
        width: 100%;
        padding: 20px;
        z-index: 10;
        max-height: 70vh;
        overflow-y: scroll;

        @media(max-width: 767px){
            width: 100%;
            position: fixed;
            left: 0;
            
        }

       
    }

}

.resultCard{
    cursor: pointer;
}

.result-Card{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin: 10px 0;
    padding: 5px 0;
    border-bottom: 1px solid #00000010;
    .result-img{
        min-width: 50px;
        max-width: 50px;
    }

    &:hover{
        background-color: #cfe1f896;
    }
}