.failed{
    background-color: rgba(238, 238, 238, 0.6);
    width: 100%;
    display: grid;
    place-items: center;
    height: 100vh;
    @media(max-width: 500px){
        background-color: #fff;
    }
    .container{
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: #fff;
        width: 50%;
        padding: 20px 0;
        @media(max-width: 804px){
            width: 80%;
            box-shadow: none;
        }
        @media(max-width: 500px){
            width: 100%;
        }

        .icon{
            font-size: 200px;
            color: rgb(241, 18, 18);
            opacity: 0.7;
            }
        h1{
            font-size: 30px;
        }
        p{
            margin: 20px 0;
            font-size: 18px;
            // li{
            //     // list-style-type: disc;
            // }
        }
        a{
            margin: 30px  0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
              font-size: 18px;
              border: 1px solid #cfd5dd;
              outline: none;
              background-color: #1a73e8;
              height: 45px;
              width: 16em;
              color: #fff;
              border-radius: 20px;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              @media(max-width: 500px){
                position: absolute;
                bottom: 50px;
   
              }
}
          }
    }
}