.family{
    width: 80%;
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;

    @media(max-width: 767px){
        width: 100%;
    }
    .member-container{
        width: 48%;
        border-radius: 10px;
        box-shadow: 0 4px 11px 3px hsla(0,0%,75.3%,.288)!important;
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        @media(max-width: 1024px){
            width: 100%;

        }
        .image{
           
            img{
                width: 150px;
                height: 150px;
                object-fit: cover;
                border-radius: 50%w;
                @media(max-width: 767px){
                    width: 80px;
                    height: 80px;
        
                }
            }
        }

        .member-details{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .name{

            }
            .member-position{

            }
            .member-contact{

            }
        }

    }
}