.orientation {
  margin: 50px 0;
  padding: 0;
  width: 100%;

  @media(max-width: 767px){
      margin: 50px 0;
  }
  .dates {
    // background-color: red;
    margin: 10px 60px 20px 60px;
    padding: 10px;
    li {
      padding: 4px 10px 2px 10px;
    }
  }
}
.tabledata {
  @media (max-width: 767px) {
    margin: 0;
  }
  margin: 20px;
  .tablehead {
    text-align: center;
    margin: 20px;
    @media (max-width: 767px) {
      font-size: 20px;
      // background-color: brown;
    }
  }
  .table {
    box-shadow: 0 4px 11px 3px hsla(0, 0%, 75.3%, 0.288) !important;
    
    border: 1px solid #00000030;
    border-radius: 10px;
    width: 60%;
    margin: auto;
    @media (max-width: 767px) {
      padding: 0;
      margin: 0;
      font-size: 11.5px;
      width: 100%;
    }
    
    table{
      width: 100%;
      margin: auto;
      border-collapse: collapse;
    }
    tr {
      th {
        padding: 10px;
      }
      .no {
        text-align: center;
      }
      td {
        border: 1px solid #00000030;
        padding: 20px;
      }
    }
    
  }
}
