.Campaigns{
    width: 80%;
    margin: auto;
    position: relative;
    margin-top: 20px;

    @media(max-width: 767px){
        width: 95%;
    }
    .camp-title{
        font-size: 2.4rem;
        // font-size: 30px;
        text-align: center;
        
        @media(max-width: 767px){
            font-size: 2rem;
        }
    }


    .left-scroll, .right-scroll{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        cursor: pointer;
    
    }
    .left-scroll{
   
        position: absolute;
        left: -50px;
        top: 50%;
        @media(max-width: 767px){
            display: none;
        }
    
    }
    
    .right-scroll{
        position: absolute;
        right: -50px;
        top: 50%;
        @media(max-width: 767px){
            display: none;
        }
    }
    
}

.campaign-container::-webkit-scrollbar{
    display: none;
}
.campaign-container{
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 100%;
    margin: auto;
    padding: 30px;
    overflow-x: scroll;
    scrollbar-width: none;

    @media(max-width: 767px){
        margin-right: 10px;
        padding: 40px 5px; 
    }
    
    .camp-container{
        
        min-width: 320px;
        max-width: 320px;
        border-radius: 20px;
        box-shadow: 10px 10px 20px 15px hsla(0,0%,75.3%,.288)!important;
        @media(max-width: 767px){
            min-width: 300px;
            box-shadow: 0 4px 11px 3px hsla(0,0%,75.3%,.288)!important;
            
        }

        .camp-img{
            width: 100%;
            position: relative;
            img{
                border-radius: 20px 20px 0 0;
                min-height: 180px;
                max-height: 180px;
                object-fit: cover;
            }

        }
        .camp-details-container{
            padding: 10px 30px;
            @media(max-width: 767px){
                
                padding: 15px 15px; 
            }
            .camp-heading{
                .title{
                    font-size: 1.4rem;
                    padding: 10px 0;
                    color: #1a73e8;
                }
            }
            
            .organiser-details{
                position: relative;
                margin: 20px 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                img{
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                p{
                    font-size: .8rem;
                }
                
            }

            .fund-details{
                margin: 10px 0;
                h2{
                    font-size: 0.9rem;
                    font-weight: 100;

                    span{
                        font-weight: bold;
                    }
                }
                progress{
                    width: 100%;
                    height: 15px;
                    color: #000;
                }
            }

            .extra-details{
                margin-top: 30px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 0.8rem;
            }

        }
        .share{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 20px;
            
            .shareicon{
                font-size: 1.3rem;
                outline: none;
            }
        }
    }
}

