.campaign-details-container{
    width: 85%;
    margin: 50px auto;

    .title{
        text-align: center;
        margin-bottom: 30px;

        @media(max-width: 767px){
            font-size: 1.2rem;
        }

    }

    .camp-details-inner-container{
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
        justify-items: center;

        @media(max-width: 767px){
            grid-template-columns: 1fr;
        }
        .left-camp-container{
            width: 100%;
            .img{
                width: 90%;
                max-height: 450px;
                margin: auto;
                object-fit: cover;

                @media(max-width: 767px){
                    width: 100%;
                }
            }
            .likes{
                left: 0;
            }
            .share{
                width: 20%;
                display: flex;
                margin: auto;
                flex-direction: row;
                justify-content: space-around;
                margin-top: 20px;
                @media(max-width: 767px){
                    width: 80%;
                }
                .shareicon{
                    font-size: 2rem;
                }
            }
            .about{
                width: 90%;

                @media(max-width: 767px){
                    width: 100%;
                }
                p{
                    margin-top: 20px;
                    margin-bottom: 50px;
                    line-height: 2;

                }
            }
        }

        .right-camp-container{
            align-self: flex-start;
            width: 100%;

            @media(max-width: 767px){
            }

            .contribute{
                padding: 20px;
                width: 100%;
                font-size: 1.5rem;
                cursor: pointer;
                border: none;
                background-color: #1a73e8;
                color: #fff;
            }


            .amount-details{
                p{
                    margin-top: 20px;
                    .money-collected{
                        font-size: 3rem;
                        font-weight: 500;

                    }

                    .total-amount{
                        font-size: 1.5rem;
                    }
                }
                progress{
                    width: 100%;
                    height: 20px;
                }

                .extra-details{
                    margin-top: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    font-size: 1.2rem;
                }

                .payment{
                    width: fit-content;
                    margin: 50px auto;

                }
            }


            
        }
    }

}