.offer-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

    .offer-error{
        width: 100%;
        background-color: rgb(241, 182, 182);
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: red;
        font-weight: 500;
    }    
    .offer-success{
        width: 100%;
        background-color: rgb(170, 243, 136);
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: green;
        font-weight: 500;
    }    


    .feedback{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-shadow: 0 4px 11px 3px hsla(0,0%,75.3%,.288)!important;

        @media(max-width: 767px){
            width: 100%;
        }
        span{
            color: red;
        }
        ul{
            li{
                padding: 5px;
            }
        }
        .btn{
            padding: 10px;
            background-color: #b4d2f8;
            border: none;
            outline: none;
            cursor: pointer;
            font-size: 1.2rem;
            margin-top: 20px;
        }
    }

    .offer-item{
        width: 48%;
        border-radius: 10px;
        box-shadow: 0 4px 11px 3px hsla(0,0%,75.3%,.288)!important;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        @media(max-width: 1024px){
            width: 100%;

        }
        
        .offer-title{
            font-size: 1.5rem;
            margin-bottom: 20px;

        }
        .input{
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            .name-input{
                width: 100%;
                padding: 10px;
                border: 1px solid #00000020;
                outline: none;
            }
            .btn{
                width: 50px;
                padding: 10px;
                background-color: #b4d2f8;
                border: none;
                outline: none;
                cursor: pointer;
            }
        }


    }
}