.HowItWorks {
  width: 100%;

  .HIW-container {
    width: 85%;
    margin: auto;
    .headings {
      margin: 0 0 10px 0;
      h1 {
        margin: 30px 0 10px 0;
        text-align: center;
        font-size: 25px;
        font-weight: 400;
      }
      hr {
        margin: auto;
        opacity: 0.4;
        width: 6rem;
      }
    }

    .content{
      h2 {
        padding: 13px 0;
        letter-spacing: 3px;
        word-wrap: break-word;
        font-size: 30px;
        font-weight: 400;
        @media (max-width: 770px) {
          text-align: left;
          font-size: 20px;
        }
      }
      .full-paragraph{   
        h2{
          padding: 13px 0;
          font-size: 25px;
          font-weight: 400;
        }     
        p {
          text-align: justify;
          height: auto;
          font-size: 19px;
          font-weight: 200;
          line-height: 2;
          padding: 10px 0;
          ul {
            li {
              font-size: 20px;
              list-style-type: circle;
              line-height: 2;
              padding: 5px;
            }
          }
        }
        .word {
          font-size: 22px;
          width: 100%;
          font-style: italic;
          line-height: 2;
          .writter {
            line-height: 2;
            text-align: right;
            margin-right: 40px;
            padding: 0px;
          }
        }
      }
      .subpara {
        width: 100%;
        margin: auto;
        // background-color: orange;
        h3 {
          padding: 10px 0;
        }
        p {
          line-height: 2;
          font-size: 19px;
          font-weight: 200;
          padding: 10px 0;
          text-align: justify;
          margin: 0px 2em;
          ul {
            li {
              font-size: 20px;
              list-style-type: lower-roman;
              padding: 8px 0 8px 5px;
            }
          }
          span {
            font-size: 21px;
            font-weight: 400;
            text-align: justify;
            line-height: 2;
            margin: 0 0 0 -2em;
            @media (max-width: 824px) {
              margin: 0 0 0 -1em;
            }
          }
        }
      }
      .paragraph {
        max-width: fit-content;
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-size: 19px;
        width: 100%;
        gap: 10px;
        margin: auto;
        @media (max-width: 760px) {
          grid-template-columns: 1fr;
        }
        p {
          text-align: justify;
          height: auto;
          width: 40vw;
          font-size: 19px;
          font-weight: 100;
          line-height: 2;
          padding: 10px;
          a {
            color: rgb(18, 18, 253);
          }
          @media (max-width: 770px) {
            width: auto;
          }
        }
      }
      
    }
  }
}
