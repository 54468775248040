

.contact{
    width: 80%;
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
    @media(max-width: 767px){
        width: 90%;
    }
    .phone, .email, .location{
        box-shadow: 0 4px 11px 3px hsla(0, 0%, 75.3%, 0.288) !important;
        width: 33.33%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;    
        justify-content: center;
        font-size: 1.2rem;
        @media(max-width: 767px){
            width: 100%;
        }
        .icon{
            font-size: 2rem;
        }

        .numbers{
            padding: 10px;
        }

    }
}

