.parish-about {
    width: 100%;
    .churchName {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 10px;
        @media (max-width: 767px) {
            font-size: 1.2rem;
        }
    }
    img{
        height: 50vh;
        object-fit: contain;
    }
    .likes {
        position: relative;
        left: 0;
        margin-bottom: 50px;
    }
    .location {
        font-weight: 400;
        font-size: 40px;
    }

    .content{
        max-width: 100%;
        word-wrap: break-word;

        @media(max-width: 767px) {
            max-width: 300px;
        }
        
        p{
            max-width: 100%;
            word-wrap: break-word;
            text-align: justify;

            a{
                color: blue;
                text-decoration: underline;

                &:hover{
                    color: rgb(111, 156, 240);
                }
            }
        }

    }
}
