.parish-committe {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .core-committe {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;
        justify-content: center;
    }
    .heading {
        width: 100%;
        text-align: center;
        margin: 50px 0;
    }
    .member-container {
        width: 48%;
        border-radius: 10px;
        box-shadow: 0 4px 11px 3px hsla(0, 0%, 75.3%, 0.288) !important;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1024px) {
            width: 100%;
            flex-direction: column;
        }
        .image {
            img {
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 50%;
                @media (max-width: 767px) {
                    width: 80px;
                    height: 80px;
                }
            }
        }

        .member-details {
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            @media (max-width: 1024px) {
                text-align: center;
            }
            .name {
            }
            .member-position {
            }
            .member-contact {
            }

            .member-title {
                color: rgb(126, 109, 206);
                font-size: 1.2rem;
            }
        }

        .offer-details {
            text-align: left;
        }
    }
}
