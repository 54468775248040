.footer-container{
    background-color: #18191a;
    color: #AAB2BD;
    }
    .list-container
    {           
        padding: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        @media(max-width: 767px){
            flex-direction: column;

        }
        .title  {
            a{
                .companyname{
                    font-size: 2rem;
                    color: #4b93f0;
                }
            }
            p{
                color: #AAB2BD;
            }

            .address{
                a{
                    p{
                        color: #ffffff;
                    }
                }
                p{
                    margin: 10px 0;
                }
            }
        }
        .details{
            .list-footer li {
                padding: 10px;
            }
            a{
                color: #AAB2BD; 
                
            }
            .list-footer li:hover{
                text-decoration: underline;
            }
        }
        .socialmedia {
            h3{
                text-align:left;
                margin-top: 10px;
            }
            ul{
                display: flex;
                flex-direction: column;
                gap: 10px;
                @media(max-width: 767px){
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    // justify-content: center;
                    // align-items: center;
                }
                li{
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: black;

                    a{
                        color: inherit;
                    }
                    
                    padding: 10px;
                    


                }
            }
           
    }
    .teamsandpolicy {
        .policy {
            margin-top: 5px;
            margin-left: 0px;
        }li{
            padding: 10px;
            a{
                color: #AAB2BD;
            }
                
        }
        li:hover{
            text-decoration: underline;
        }
        }
    }
.licence {
    p{
        text-align: center;
        padding: 0px 20px 20px 0px;
        a{
            // font-size: 1.4rem;
            color: #1a73e8;
        }
    }
}
