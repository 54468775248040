.header{
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  border-bottom: 1px solid #E2E8F0;
  box-shadow: 10px 10px 18px -15px rgba(0,0,0,0.51);
  -webkit-box-shadow: 10px 10px 18px -15px rgba(0,0,0,0.51);
  -moz-box-shadow: 10px 10px 18px -15px rgba(0,0,0,0.51);
}

.current{
  color: #1a73e8 !important;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .1rem 2.5rem;
}
.navbar .logo {
  width: 200px;
}

.navbar .logo-sm {
  width: 50px;
  display: none;
}

@media(max-width: 767px){
  .navbar .logo-sm{
    display: flex;
    justify-self: left;
  }
  .navbar .logo {
    display: none;
  }
  
}

.navbar .search-bar{
  border-radius: 8px;
  width: 70%;
  position: relative;
  background-color: #ebedf5;
}

.navbar .search{
  padding: 10px;
  outline: none;
  border: none;
  font-size: 1rem;
  border-radius: 8px;
  width: 100%;
  position: relative;
  background-color: #ebedf5;
  
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-item {
  margin-left: 2rem;
  font-weight: 500;
}

.nav-item a:hover {
  color: #1a73e8;
}


.nav-link{
  font-size: 1rem;
  font-weight: 400;
  color: #475569;
}

.nav-link:hover{
  color: #1a73e8;
}

.nav-logo {
  font-size: 2.1rem;
  font-weight: 700;
  color: #1a73e8;
}




@media only screen and (max-width: 768px) {  
  .navbar {
      justify-content: space-between;
      padding: 0.5rem 1rem;
  }
  .nav-menu {
      position: fixed;
      left: -100%;
      top: 3.5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      z-index: 100;
      box-shadow:
          0 10px 27px rgba(0, 0, 0, 0.05);
  }

  .nav-logo{
      font-size: 1.5rem;
  }
  .nav-menu.active {
      left: 0;
  }

  .nav-item {
      margin: 1.5rem 0;
  }

  .hamburger {
      display: block;
      cursor: pointer;
  }
  .hamburger.active .bar:nth-child(2) {
      opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
  }


}