.main-container{
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 150px;
    // align-items: center;
    @media(max-width: 767px){
        grid-template-columns: 1fr;
        gap: 50px;
    }
    .church_detail{
        @media(max-width: 767px){
            width: 100%;
        }
        
        h1{
            font-weight: 500;
        }
        p{
            padding: 20px;
        }
    }
    .details{
        padding: 20px;
        width: fit-content;
        // justify-self: center;
        
        @media(max-width: 767px){
            width: 90%;
            padding: 0;
            margin: auto;
        }

        
    }
    .parish-contents{
        width: 90%;
        margin: auto;
        grid-column: 1/2;
    }
}
.inner-nav{
    margin: 30px;
    justify-self: center; 
    display: flex;
    flex-direction: column;
    @media(max-width: 767px){
        margin:  30px 0;
    }
    ul{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 5px;
        border-radius: 10px;
        box-shadow: 0 4px 11px 3px hsla(0, 0%, 75.3%, 0.288) !important;
        @media(max-width: 767px){
            padding: 0px;
        }
        li{
            padding: 10px 15px;
            text-transform: uppercase;
            @media(max-width: 767px){
                padding: 5px;
            }
        }
        li:hover{
            font-weight: 600;
        }
        .active{
            background-color: rgb(225, 229, 248);
            font-weight: 600;
        }
    }
}
