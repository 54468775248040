@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: 'Quicksand', sans-serif;
    scroll-behavior: smooth;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: black;
}

img{
    width: 100%;
}

button{
    outline: none;
}

.nothing{
  height: 300px;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.error{
  display: flex;
  align-items: center;
  width: fit-content;
  box-shadow: 0 4px 11px 3px hsla(0, 0%, 75.3%, 0.288) !important;
  background-color: rgb(228, 152, 152);
  color: red;
  padding: 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.nerchapettiId{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nerchapettiId .copybtn{
  cursor: pointer;
  padding: 2px;
  border: none;
  outline: none;
  margin-left: 10px;
}


.isLoading,
.isLoading:before,
.isLoading:after {
  background: #1a73e8;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.isLoading {
  color: #1a73e8;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.isLoading:before,
.isLoading:after {
  position: absolute;
  top: 0;
  content: '';
}
.isLoading:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.isLoading:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
