.head {
  width: 100%;
  height: 40px;
  text-align: center;
  margin: 15px 0;
  h2 {
    font-size: 30px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 10px 0;
  }
  hr {
    opacity: 0.4;
    width: 70%;
    margin: auto;
  }
}
.content1 {
  width: 80%;
  margin: auto;
  padding: 25px 0;
  box-sizing: border-box;
  h2 {
    font-weight: 400;
  }
  .full-paragraph {
    width: 80vw;
    margin: auto;
    // background-color: red;
    @media (max-width: 824px) {
      width: 90%;
    }

    p {
      text-align: justify;
      height: auto;
      font-size: 19px;
      line-height: 2;
      font-weight: 200;
      padding: 10px 0 10px 10px;
      ul {
        padding: 0 0 0 10px;
        li {
          list-style-type: lower-roman;
        }
      }
    }
    .word {
      font-size: 22px;
      width: 100%;
      font-style: italic;
      .writter {
        text-align: right;
        margin-right: 40px;
        padding: 0px;
      }
    }
  }
}
.main-container-about {
  margin: 0;
  padding: 0;
  .about {
    width: 80%;
    margin: auto;
    @media (max-width: 767px) {
      width: 100%;
    }
    h1 {
      text-align: center;
      padding: 20px;
    }

    p {
      text-align: center;
      @media (max-width: 767px) {
        margin: 2px 10px;
      }
    }
  }
  .team {
    width: 90%;
    margin: 50px auto;
    @media (max-width: 767px) {
      width: 100%;
    }
    h1 {
      text-align: center;
      font-size: 25px;
      font-weight: 400;
      padding: 0 0 5px 0;
    }
    hr {
      width: 3.5rem;
      margin: auto;
    }
    p {
      text-align: center;
      margin: 5px;
      padding: 30px;
      @media (max-width: 767px) {
        margin: 2px 10px;
      }
    }
    .position {
      font-size: 2rem;
      margin-top: 50px;
    }
    .team-members {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        display: flex;
        flex-direction: row;
      }
      .member {
        // background-color: orange;
        display: flex;
        flex-direction: column;
        margin: 20px;
        border: 1px solid #00000030;
        width: 250px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        @media (max-width: 546px) {
          width: 70vw;
        }
        img {
          object-fit: cover;
          padding: 10px;
          margin: auto;
          height: 200px;
          width: 200px;
          background-size: cover;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }
        .name {
          text-align: center;
          font-size: 1rem;
          margin-top: 10px;
        }
        p {
          padding: 8px 0;
        }
        .detail-icon {
          font-size: 25px;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          cursor: pointer;
          margin-bottom: 10px;
        }
      }
    }
  }
}
