.main{
    margin: 0;
    padding: 0;
    width: 100%;
    .main-container-patron{
        box-shadow: 0 4px 11px 3px rgba(192, 192, 192, 0.288) !important;
        width: 80%;
        margin: auto;
        @media (max-width: 767px) {
            
        }
         
    }
}
.details
{
    .detail{
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: 767px){
            margin-left: -10px;
            font-size: 10px;
        }
        ul{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 10px;
            align-items: center;
            width: fit-content;
            border-radius: 10px;
            box-shadow: 0 4px 11px 3px hsla(0, 0%, 75.3%, 0.288) !important;
            justify-content: center;
            @media(max-width: 767px){
                margin: auto;
                padding: 4px;
            }
            li{
                padding: 10px 15px;
                text-transform: uppercase;
                @media(max-width: 767px){
                    padding: 8px;
                }
            }
            li:hover{
                font-weight: 600;
            }
            .active{
                background-color: rgb(225, 229, 248);
                font-weight: 600;
            }
        }
    }
}



.allpatrons {
  width: 80%;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    width: 95%;
    gap: 30px;
  }
  .patron {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0 4px 11px 3px hsla(0, 0%, 75.3%, 0.288) !important;
    padding: 20px 0;

    .image {
      margin: auto;
      img{
          border-radius: 50%;
          height: 100px;
          width: 100px;
          object-fit: cover;
        
      }
    }

    .details {

      h1 {
        font-size: 1.5rem;
      }
      p{

      }
    }
}
}