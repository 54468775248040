.heading-contact {
  margin: 30px 0;
  text-align: center;
  text-transform: lowercase;
  font-size: 25px;
  font-weight: 400;
}
.contactdata {
  margin: 0 auto;
  width: 80vw;
  margin-top: 10px;
  margin-bottom: 20px;
  column-gap: 60px;
  display: grid;
  grid-template-columns: 1fr 2fr;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    row-gap: 10px;
  }
  .contact-container {
    min-width: 20vw;
    margin: 0px auto;
    // background-color: red;

    @media (max-width: 1023px) {
      margin: 0px;
    }
    .contactdatas {
      // .icons{
      //   color: #1a73e8;
      // }
      p:nth-child(1) {
        display: flex;
        flex-direction: column;
      }
      p {
        margin: 10px auto;
        a {
          margin: 0 0 10px 0;
        }
      }
      .span {
        font-size: 23px;
        margin: 30px 0;
      }
    }
  }
  .contact-form {
    width: 60vw;
    justify-content: center;
    margin: 20px auto;
    @media (max-width: 1023px) {
      width: 80vw;
    }
    .allcontain {
      width: 60vw;

      @media (max-width: 1023px) {
        width: 80vw;
      }
      .selects {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        @media (max-width: 570px) {
          grid-template-columns: 1fr;
          row-gap: 45px;
        }
        select,
        input {
          background-color: #fff;
          height: 40px;
          width: 18.5vw;
          border-radius: 4px;
          outline: none;
          position: absolute;
          border: 1px solid #e8eaed;
          padding: 9px;
          @media (max-width: 1023px) {
            width: 35vw;
          }
          @media (max-width: 570px) {
            width: 77vw;
          }
        }
        select option {
          outline: none;
          width: 18.5vw;
          padding: 10px;
        }
      }
    }
    .form-data-message {
      margin: 40px 0 20px 0;
      width: 50vw;
      textarea {
        border-radius: 4px;
        outline: none;
        border: 1px solid #cfd5dd;
        max-height: 75px;
        min-height: 38px;
        width: 50vw;
        padding: 9px;
        box-sizing: border-box;
        font-size: 15px;
        &::placeholder{
          font-family: sans-serif;
          font-size: 14px;
        }
        @media (max-width: 1023px) {
          width: 77vw;
        }
      }
    }
    .form-data-submit {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      width: 50vw;

      @media (max-width: 1000px) {
        justify-content: center;
        margin: auto;
      }
      input {
        font-size: 17px;
        border: 1px solid #cfd5dd;
        outline: none;
        background-color: #1a73e8;
        height: 30px;
        width: 18vw;
        color: #fff;
        border-radius: 20px;
        @media (max-width: 910px) {
          width: 40vw;
        }
        &:hover {
          font-size: 19px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
            rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        }
      }
    }
  }
}
