.Blogs-container{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    .main-container{
        display: inline;
        .head{
            width: 100%;
            height: 40px;
            text-align: center;
            margin: 15px 0;
            h2{
                font-size: 30px;
                text-transform: uppercase;
                font-weight: 400;
                padding: 0 15px;
                margin: 0 0 10px 0;
            }
            hr{
                width: 70%;
                margin: auto;
            }
        }
        .search{
            padding: 20px;
            width: 85%;
            margin: auto;
            display: grid;
            place-items: center;
            input{
                border-radius: 50px;
                border: 0.2px solid rgb(209, 204, 204);
                padding: 10px;
                outline: none;
                height: 45px;
                width: 40vw;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                @media(max-width: 600px){
                    width: 70vw;
                    height: 40px;
                }
            }
        }
        .blogs{
            
            cursor: pointer;
            padding: 20px 0;
            width: 95%;
            margin: auto;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            row-gap: 40px;
            flex-direction: row;
            justify-content: space-around;
            
            .blog{
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                padding: 10px;
                display: flex;
                flex-direction: row;
                width: 550px;
                height: auto;
                @media (max-width: 600px){
                    width: 95%;
                }
                &:hover{
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                }
                @media(max-width: 600px){
                    flex-direction: column;
                }
                img{
                    object-fit: cover;                    
                    background-size: cover;
                    width: 250px;
                    margin-right: 25px;
                    @media(max-width: 600px){
                        width: 100%;
                        object-fit: cover;
                        height: 70vh;
                    }
                }
                .blog-data{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    .auth-date{
                        display: flex;
                        img{
                            object-fit: cover;
                            background-size: cover;
                            height: 50px;
                            width: 50px;
                            border-radius: 30px;
                            -webkit-border-radius: 30px;
                            -moz-border-radius: 30px;
                            -ms-border-radius: 30px;
                            -o-border-radius: 30px;
}
                        .auth{
                            display: flex;
                            flex-direction: column;
                        }
                    }
                    h2{
                        overflow: hidden;
                        max-height: 80px;
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        word-spacing: 5px;
                        text-align: justify;
                        word-break: break-all;
                        @media(max-width: 600px){
                            padding: 10px 0;
                        }
                    }
                    p{
                        word-break: break-all;
                        word-spacing: 5px;
                        // background-color: orange;
                        max-height: 250px;
                        overflow: hidden;
                        @media(max-width: 600px){
                            padding: 10px 0;
                        }
                    }
                    .content{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        span{
                            font-weight: 100;
                        }
                    }
                }
            }
        }
    }
}