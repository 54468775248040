.BlogDetails {
    width: 100%;
  
    .BlogContent {
      text-align: left;
      width: 70%;
      max-width: 1000px;
      margin: auto;
      font-family: serif;
      .heading {
        h1 {
          text-align: inherit;
          font-size: 250%;
          margin: 20px 0;
          @media (max-width: 1000px) {
            font-size: 20px;
          }
        }
      }
      .content {
        margin: 20px 0;
        //   display: grid;
        //   grid-template-columns: 1fr 1fr;
        //   gap: 20px;
        //   @media (max-width: 1000px) {
        //     grid-template-columns: 1fr;
        //   }
        .auth-date {
          display: flex;
          img {
            object-fit: cover;
            background-size: cover;
            height: 50px;
            width: 50px;
            border-radius: 30px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
          }
          .auth {
            margin: 0 0 20px 10px;
            display: flex;
            flex-direction: column;
  
            span {
              margin: 0 10px;
              font-size: 20px;
            }
          }
        }
        img.blogImg {
          @media (min-width: 1000px) {
            width: 100%;
            height: auto;
            margin: 20px 0;
          }
  
          @media (max-width: 1000px) {
            // height: 50vh;
          }
        }
        div {
          p {
            margin: 30px auto;
            padding: 0 15%;
            letter-spacing: 2px;
            font-size: 20px;
            text-align: justify;
            @media (max-width: 1000px) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  