.success {
  background-color: rgba(238, 238, 238, 0.6);
  width: 100%;
  display: grid;
  place-items: center;
  .success-container {
    margin: 40px 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    width: 50%;
    // height: 100vh;

    @media (max-width: 600px) {
      width: 100%;
      box-shadow: none;
      margin: 0;
    }
    .page-wrapper {
      height: 25vh;
      width: 50%;
      background-color: #fff;
      display: flex;
      align-items: center;
      margin: auto;
      justify-content: center;

      @media (max-width: 600px) {
        width: 100%;
      }

      .circle-wrapper {
        position: relative;
        width: 100px;
        height: 100px;
        float: left;
        margin: 10px;
        .icon {
          position: absolute;
          color: #fff;
          font-size: 45px;
          top: 56px;
          left: 50px;

          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
        }

        .circle {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          padding: 2.5px;
          background-clip: content-box;
          animation: spin 10s linear infinite;
          -webkit-animation: spin 10s linear infinite;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }

        &:active .circle {
          animation: spin 2s linear infinite;
          -webkit-animation: spin 2s linear infinite;
        }

        .success {
          background-color: rgba(76, 185, 68, 1);
          border: 2.5px dashed #4bb543;
        }
      }
    }
    .payment-details {
      // width: 50%;
      height: 400px;
      background-color: #fff;
      // background-color: orange;

      margin: auto;
      @media (max-width: 600px) {
        width: 100%;
      }
      p {
        text-align: center;
        font-size: 20px;
        padding: 0 0 5px 0;
      }
      p:nth-child(2) {
        display: block;
      }
      table {
        // background-color: red;
        // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 40px 0;
        border: none;
        width: 60%;
        margin: auto;
        @media (max-width: 600px) {
          width: 80%;
        }
        tr:nth-child(1) {
          th {
            text-align: center;
            padding: 0 0 10px 0;
          }
        }
        tr {
          td {
            padding: 6px;
          }
        }
      }
    }
    .button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        font-size: 17px;
        border: 1px solid #cfd5dd;
        outline: none;
        background-color: #1a73e8;
        height: 35px;
        width: 16em;
        color: #fff;
        border-radius: 20px;
      }
    }
  }
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
  }
}
