.bank_details{
    width: fit-content;
    margin: auto;
    position: sticky;
    top: 10px;
    @media(max-width: 767px){
        width: 95%;
    }

    .list{
        padding: 10px;
        display: flex;
        border-radius: 10px;
        flex-direction: column;
        background-color: #5493e6;
        color: #cfd3da;
        margin: auto;
        .head
        {
            color: cornsilk;
            text-align: center;
            padding: 15px;
            font-weight: 600;
        }
        box-shadow: -2px -1px 8px 1px rgba(0,0,0,0.55);
        li{
            padding: 6px;
            font-size: 15;
            span{
                padding: 10px;
                color:cornsilk;
                font-size: 13px;
            }
        }
        .button{
            padding: 25px;
            align-self: center;
            button{
                cursor: pointer;
                outline: none;
                height: 40px;
                width: 250px;
                font-size: 1.2rem;
                background-color: #d5dfff;
                color: rgb(0, 0, 0);
                border: none;
                border-radius: 20px;
                &:hover{
                    background-color: #384657;                    
                    color: #fff;
                }
                @media(max-width: 767px){
                    width: 200px;
                }
            }
            

        }
    }
}