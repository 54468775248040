.likes{
    
    position: absolute;
    right: 0px;
    background-color: #00000010;
    width: 70px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    
}

.liked{
    color: #1a73e8;
}
